@font-face {
  font-family: Montserrat;
  src: url(../public/Montserrat-VariableFont_wght.ttf);
}

#root {
  /* display: flex;
  flex-direction: column; */
  /* align-items: stretch; */
  /* height: 100vh;
  background-color: purple; */
  /* overflow: hidden; */
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  /* @media screen {
    min-width: 100vh;
  } */
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
